import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout title="Nothing found here...">
    <SEO title="404: Not found" />
    <p>
      <Link to="/">Go to start</Link>
    </p>
  </Layout>
)

export default NotFoundPage
